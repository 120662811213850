<template>
	<div class="py-6 py-md-9 product-item"
		 v-scroll="onScroll" :ref="'item'+item.ID">

		<div class="product-item__self text-center d-flex flex-column align-center" :class="{loaded:item.loaded}">
			<router-link :to="to"
						 :style="isMD && item.PREVIEW_STYLE?item.PREVIEW_STYLE:null"
						 class="product-item__image" :class="linkClass">
				<v-img v-if="item.PREVIEW_PICTURE" :src="item.PREVIEW_PICTURE" :alt="item.NAME"
					   :class="{image:item.HOVER}" :style="imageClass"
					   contain
					   @load="onLoaded"/>
				<v-img v-if="item.HOVER" :src="item.HOVER" :alt="item.name" contain
					   class="hover" :style="isMD && item.HOVER_STYLE?item.HOVER_STYLE:null"/>
			</router-link>

			<div class="mt-8 product-item__text relative z-index-2">
				<router-link :to="to">
					<h5 v-html="nameHtml"></h5>
					<div v-if="subnameHtml" class="size2" v-html="subnameHtml"></div>
				</router-link>
				<div class="h5 mt-2 product-item__price">{{item.PRICE | price(2)}} €</div>

				<router-link v-if="basketAmount" :to="{name:'basket'}"
							 class="basket-amount">{{basketAmount}}</router-link>
			</div>
		</div>

    <transition name="fade">
      <div v-if="item.VIDEO_LINK && isAnimationVisible" class="product-list__animation">
        <animation class="d-none d-md-block"
                   :video="item.VIDEO_LINK"
                   :position="'height:' + item.VIDEO_HEIGHT + 'px;' + 'top:' + item.VIDEO_TOP + '%;'"/>
      </div>
    </transition>

	</div>
</template>

<script>
	import Vue from "vue";
	import vp from "@/mixins/vp";

  import Animation from "@/views/Category/animations/main-animation";

	export default {
		name: "product-item",
		mixins: [vp],
		components: {
      Animation
		},
		props: ["item", "category", "flavour"],
		data() {
			return {
				isAnimationVisible: false
			}
		},
		computed: {
			linkClass() {
				if (!this.item.COLS) return null;
				//if (!this.isMD) return null;
				return "cols-" + this.item.COLS;
			},
			imageClass() {
				const c = {};
				if ( !this.isMD && this.item.PREVIEW_WIDTH_XS ) {
					if ( this.item.PREVIEW_WIDTH_XS ) c.width = this.item.PREVIEW_WIDTH_XS+"px";
					if ( this.item.PREVIEW_HEIGHT_XS ) c.height = this.item.PREVIEW_HEIGHT_XS+"px";
				}
				else {
					if ( this.item.PREVIEW_SIZE.width ) c.width = this.item.PREVIEW_SIZE.width+"px";
					if ( this.item.PREVIEW_SIZE.height ) c.height = this.item.PREVIEW_SIZE.height+"px";
				}
				return c;
			},
			to() {
				if (this.flavour) {
					return {
						name: 'product',
						params: {
							sectionCode: this.categoryCode,
							code: this.item.CODE
						},
						query: {
							flavourId: this.flavour.ID
						}

					};
				}
				return {name: 'product', params: {sectionCode: this.categoryCode, code: this.item.CODE}};
			},
			nameHtml() {
				const item = this.item;
				//return item.name.substr(0, 1).toUpperCase() + item.name.substr(1).toLowerCase();
				let name = item.NAME;
				name = name.replace(/(^.+?)(in )?(dark|milk).+$/is, "$1");
				name = name.replace(/(^.+?)filled with .+$/is, "$1");
				name = name.replace(/(^.+?)\d+ g$/is, "$1");
				name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1");
				/*if ( name.match(/(^.+?)(\d+ pcs)$/is) ) {
					name = name.replace(/(^.+?)(\d+ pcs)$/is, "$1<br>$2");
					if ( item.WEIGHT ) name += " / "+item.WEIGHT + " g";
				}
				else if ( item.WEIGHT ) name += "<br>"+item.WEIGHT + " g";*/

				return name;
			},
			subnameHtml() {
				const item = this.item;
				if (item.SUBNAME) return item.SUBNAME;
				//return item.choco.substr(0, 1).toUpperCase() + item.choco.substr(1).toLowerCase();
				let choco = null;
				if (item.NAME.match(/.+?filled with .+$/)) {
					choco = item.NAME.replace(/.+?(filled with.+$)/is, "$1");
					choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
				} else if (item.NAME.match(/.+?(in )?(dark|milk).+$/)) {
					choco = item.NAME.replace(/.+?((in )?(dark|milk).+$)/is, "$1");
					choco = choco.replace(/(.+?)(\d+ g$)/is, "$1<br>$2");
				} else if (item.NAME.match(/\d+ g$/)) {
					// todo make this related to item.WEIGHT
					choco = item.NAME.replace(/.+?(\d+ g$)/is, "$1");
				} else if (item.NAME.match(/\d+ pcs$/)) {
					choco = item.NAME.replace(/.+?(\d+ pcs$)/is, "$1");
					if (item.WEIGHT) choco += " / " + item.WEIGHT + " g";
				}

				return choco;
			},
			categories() {
				return this.$store.state.common.sections;
			},
			categoryCode() {
				if (this.category) return this.category.CODE;
				/*console.log("CATS: ", this.categories);
				console.log("ITEM: ", this.item);*/
				return this.categories.find(el => el.ID === this.item.IBLOCK_SECTION_ID).CODE;
			},
			basket() {
				return this.$store.state.session.basket;
			},
			basketAmount() {
				return this.basket?.reduce((total, el) => {
					if (Number(el.PRODUCT_ID) === Number(this.item.ID)) {
						total += el.AMOUNT;
					}
					return total;
				}, 0);
			},
		},
		methods: {
			onLoaded() {
				setTimeout(() => Vue.set(this.item, "loaded", true), 300);
				setTimeout(() => this.isAnimationVisible = true, 500);
			},
			onScroll() {
				/*const item = this.animatedItem;
				if (!item) return;
				const ref = this.$refs['item' + item.ID];
				if (!ref) return;
				if (!ref[0]) return;
				if (!ref[0].$el) return;
				// todo find out why [0] is needed?
				const rect = ref[0].$el.getBoundingClientRect();
				this.isAnimationVisible = rect.top < window.innerHeight * .75 && rect.bottom > window.innerHeight * .5;*/
			},
		},
		/*mounted() {
			setTimeout(()=>{
				this.onScroll();
			},150);
		}*/
	}

</script>

<style lang="scss">
	.product-item {
		position: relative;

		&__self {
			position: relative;
			max-width: 280px;
			@include transition();
			opacity: 0;
			transform: translateY(-10px);
			z-index: 1;

			&.loaded {
				opacity: 1;
				transform: translateY(0);
			}
		}

		&__image {
			position: relative;
			display: block;

			&:hover {
				opacity: unset;
			}

			.v-image {
				//mix-blend-mode: multiply;
				filter: brightness(1.01); // для скрытия серго фона jpg
				@include transition();

				@include up($md) {
					&:not(.image):not(.hover) {
						&:hover {
							filter: brightness(1.02) contrast(1.02);
							transform: translateY(-2px); // rotate(1deg);
						}
					}

					&.image {
						z-index: 1;

						&:hover {
							opacity: 0;

							~ .hover {
								opacity: 1;
							}
						}
					}
				}

				&.hover {
					display: none;
					position: absolute;
					top: 50%;
					left: 50%;
					//z-index: -1;
					opacity: 0;
					//width: 100%;
					transform: translateX(-50%) translateY(-50%);
					mix-blend-mode: multiply;

					@include up($md) {
						display: block;
					}
				}
			}

			// по умолчанию - всегда выравнивание по физическому размеру картинок

			// для кастомных колонок 6+ на мобиле ограничиваем ширину
			&.cols-6, &.cols-8, &.cols-12 {
				width: 100%;
				max-width: 320px;

				@include up($md) {
					width: unset;
					max-width: unset;
				}

			}
		}

		&__price {
			color: $black !important;
		}

		&__text {
			position: relative;

			.basket-amount {
				right: -8px;
			}
		}

		&__animation {
			display: none;
			@include up($md) {
				display: block;
			}
		}
	}
</style>