<template>
	<div class="product-list">
		<v-container>
			<v-row justify="center" v-for="(items, index) in rows" :key="'row'+index">
				<v-col v-for="(item, colIndex) in items"
					   :key="'col'+item.ID"
					   cols="12" class="d-flex justify-center"
					   :style="colStyle(item, colIndex)"
					   :md="colsMD(item)"
					   :offset-md="offsetMD(item)">

					<product-item :item="item" :category="category"
								  :flavour="flavour"/>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
	import ProductItem from "./product-item";
	import vp from "@/mixins/vp";

	export default {
		name: "product-list",
		mixins: [vp],
		components: {
			ProductItem,
		},
		props: ["category", "items", "flavour"],
		data() {
			return {
				isAnimationVisible: false,
			}
		},
		computed: {
			rows() {
				const rows = [];
				let items = [];
				this.items.forEach(el => {
					if (el.WITH_NEW_ROW && items.length) {
						rows.push(items);
						items = [el];
					} else items.push(el);
				});
				rows.push(items);
				return rows;
			},
			animatedItem() {
				return this.items.find(el => !!el.ANIMATION_ID);
			}
		},
		methods: {
			colStyle(item, index) {
				let s = ["z-index:"+(32-index)];
				if ( this.isMD && item.PREVIEW_COL_STYLE ) s.push(item.PREVIEW_COL_STYLE);
				return s.join(";");
			},
			colsMD(item) {
				if (!item.COLS) return 4;
				return parseInt(item.COLS);
			},
			offsetMD(item) {
				if (!item.OFFSET) return null;
				return parseInt(item.OFFSET);
			},
			onScroll() {
				const item = this.animatedItem;
				if (!item) return;
				const ref = this.$refs['item' + item.ID];
				if (!ref) return;
				if (!ref[0]) return;
				if (!ref[0].$el) return;
				// todo find out why [0] is needed?
				const rect = ref[0].$el.getBoundingClientRect();
				this.isAnimationVisible = rect.top < window.innerHeight * .75 && rect.bottom > window.innerHeight * .5;
			},
		},
		mounted() {
			setTimeout(() => {
				this.onScroll();
			}, 150);
		}
	}
</script>

<style lang="scss">
	.product-list {
		.container {
			max-width: 1100px;
			min-height: 800px;

			/*@include up($md) {
				overflow: hidden;
			}
			@include up($lg) {
				overflow: unset;
			}*/
		}


		// todo naming
		/*.category {
			&-animation {
				@include up($md) {
					//height: auto;
					//margin-top: 0;
					position: absolute;

					width: 0;
					//max-width: $lg;
					height: 480px;

					left: 50%;
					top: 0;
					transform: translate(-50%) scale(1);
				}
			}
		}*/
	}
</style>